<template>
  <div id="register">
<!--    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/banner1.png"
        alt=""
        class="register-banner-can-be-dragged"
        ref="register-banner-can-be-dragged"
        :style="{
          right: currentBannerX + 'px',
          top: currentBannerY + 'px'
        }"
        @touchstart="touchstartRegisterBanner"
        @touchend="touchendRegisterBanner"
        @click="clickBannerShowToast"
    />-->
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/blue-logo.png"
        alt=""
        style="width: 0.8rem; margin-bottom: 0.267rem;"
        v-if="hasBlueLogo"
    />
    <div
        class="wallet-logo-container"
        v-if="has51WalletLogo"
    >
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/51-wallet-blue-logo.png"
          alt=""
          v-if="has51WalletName"
      />
      <span>51微钱包</span>
    </div>
    <div
        class="wallet-logo-container"
        v-if="has51CardLogoAndName"
    >
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/blue-logo.png"
          alt=""
      />
      <span>51微卡包</span>
    </div>
    <div
        class="wallet-logo-container"
        v-if="hasWeiXiangHuaLogoAndName"
    >
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/wei-xiang-hua-logo.png"
          alt=""
      />
      <span>唯享花</span>
    </div>
    <div class="loan-info-container">
      <img
          :src="loanInfoContainerTitleImageUrl"
          alt=""
      />
      <div class="title">
        <span v-show="!isTest">你想借多少？</span>
        <span v-show="isTest">你想借多少？(具体金额以实际审批为准)</span>
      </div>
      <div class="loan-money-container">
        <input
            type="number"
            v-model="loanMoney"
            @input="limitingLoanMoney"
        />
        <div class="loan-money-operate-container">
          <span @click="loanMoney = 50000">全部借出</span>
          <em>(金额可修改)</em>
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/clear-icon.png"
              alt=""
              @click="loanMoney = 20000"
          >
        </div>
      </div>
      <div class="loan-way">
        <div>
          <span>借款期限</span>
          <em>可选3,6,12,36期</em>
        </div>
        <div>
          <span>还款方式</span>
          <div>
            <div>
              <em v-show="!isTest">随借随还</em>
              <em v-show="isTest">还款灵活</em>
            </div>
            <div>具体方式需根据最终资方而定</div>
          </div>
        </div>
        <div v-show="specialRegister">
          <span>年化利率</span>
          <div>
            <div>
              <em>24%-36%(单利)</em>
            </div>
            <div>实际以审核为准</div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-form-container">
      <input
          type="text"
          placeholder="请输入手机号"
          v-model="loginForm.phone"
          @input="loginForm.phone = loginForm.phone.slice(0, 11)"
      />
      <div
          class="submit-button"
          @click="getVerificationCode"
          v-throttle="1500"
      >
        {{ submitButtonText }}
      </div>
      <div class="protocol-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/protocol-is-selected.png"
            alt=""
            v-show="protocolIsSelected"
            @click="protocolIsSelected = !protocolIsSelected"
        >
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/protocol-is-not-selected.png"
            alt=""
            v-show="!protocolIsSelected"
            @click="protocolIsSelected = !protocolIsSelected"
        >
        <span>我已阅读并同意</span>
        <em @click="showProtocolContentPopup(0)">《注册协议》</em>
        <em @click="showProtocolContentPopup(1)">《隐私协议》</em>
      </div>
    </div>
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/banner3.png"
        alt=""
        @click="clickBannerShowToast"
        v-show="!noBanner"
        style="margin-bottom: 0.267rem;"
    />
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/credit-report-banner.png"
        alt=""
        v-show="!noCreditReport"
    />
    <div
        class="submit-history-list-container"
        v-show="!noHistory"
    >
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/swipe-title.png"
          alt=""
      />
      <div class="submit-history-list">
        <div
            class="submit-history-list-item"
            v-for="submitHistory in submitHistoryList"
        >
          <div class="submit-user-info">
            <img
                :src="submitHistory.avatarUrl"
                alt=""
            />
            <div class="user-name">{{ submitHistory.name }}</div>
            <div>{{ submitHistory.phoneNumber }}</div>
          </div>
          <div>申请时间：{{ currentDay }}</div>
          <div>获额时间：{{ currentDay }}</div>
          <div class="submit-money">
            <div><em>{{ submitHistory.money }}</em></div>
            <div>获批额度</div>
          </div>
        </div>
      </div>
    </div>
    <div class="loan-prompt-text">
      <img
          :src="loanPromptTextImageUrl"
          alt=""
      />
    </div>
    <footer>
      <div
          class="company-info"
          v-show="!haveNoCompany"
      >
        <div>{{ companyName }}</div>
        <div>{{ companyAddress }}</div>
        <div>
          <a href="https://beian.miit.gov.cn" target="_blank">{{ ICP }}</a>
        </div>
      </div>
      <div v-show="hasCustomer">客服电话：4000606039(工作日9:00-18:00)</div>
    </footer>
    <van-popup
        round
        v-model="verificationCodeInputIsShow"
        closeable
    >
      <div class="verification-code-input-container">
        <h3>请输入验证码</h3>
        <CodeInputContainer
            ref="code-input-container"
            @doLogin="doLogin"
        />
        <div
            class="count-down-text"
            v-show="countDown !== 60"
        >
          <em>{{ countDown }}s</em>后重新获取验证码
        </div>
        <div
            class="count-down-text"
            v-show="countDown === 60"
            @click="getVerificationCode"
            v-throttle="1500"
        >
          <em>重新获取验证码</em>
        </div>
        <div
            class="submit-button"
            @click="doLogin"
            v-throttle="1500"
        >
          领取额度
        </div>
      </div>
    </van-popup>
    <van-popup
        round
        position="bottom"
        v-model="protocolContentPopupIsShow"
        :close-on-click-overlay="!protocolPopupClickOverlayNotToHide"
    >
      <div class="protocol-content-popup-container">
        <div
            class="protocol-content"
            v-html="protocolContentHtml"
            v-show="!protocolIsIframe"
        >
        </div>
        <iframe
            class="protocol-content"
            :src="protocolIframeUrl"
            frameborder="0"
            scrolling="auto"
            v-show="protocolIsIframe"
        />
        <div
            class="submit-button"
            @click="protocolContentPopupIsShow = false"
        >
          好的
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import CodeInputContainer from '_components/CodeInputContainer.vue'

import { get_code, do_login } from '_network/register'
import { get_agreement_list } from '_network/submitInfo'

import getSearch from '_utils/getSearch'

import submitUserInfo from './submitUserInfo'

export default {
  components: {
    CodeInputContainer
  },
  data() {
    return {
      // 今日日期
      currentDay: '',
      // 借款输入框绑定值，仅做展示用
      loanMoney: 20000,
      // 当前广告所处位置的X和Y轴
      currentBannerX: 0,
      currentBannerY: 88,
      // 验证码输入框显隐状态
      verificationCodeInputIsShow: false,
      // 获取验证码的倒计时
      countDown: 60,
      // 接收倒计时的定时器容器
      timer: null,
      // 用户登录信息
      loginForm: {
        phone: '',
        verificationCode: ''
      },
      // 协议勾选状态(当渠道存在VUE_APP_NOT_AGREE环境变量时默认不勾选)
      protocolIsSelected: !process.env.VUE_APP_NOT_AGREE,
      // 协议内容展示弹窗
      protocolContentPopupIsShow: false,
      // 协议内容(富文本)
      protocolContentHtml: '',
      // 注册协议+隐私协议集合(富文本)
      protocolContentHtmlList: ['', ''],
      // 当前展示用的iframe的地址
      protocolIframeUrl: '',
      // 部分渠道采用链接协议时的iframe地址列表
      protocolIframeUrlList: [
        HxRequestApi + process.env.VUE_APP_REGISTER_PROTOCOL_URL,
        HxRequestApi + process.env.VUE_APP_SECRET_PROTOCOL_URL
      ],
      // 底部用户获取申请信息列表
      submitHistoryList: [],
      // 暂停登录
      stopLogin: false,
      // 根据不同渠道的环境变量
      protocolIsIframe: process.env.VUE_APP_PROTOCOL_IS_IFRAME,
      bottomRate8To24: process.env.VUE_APP_REGISTER_BOTTOM_RATE_8_TO_24,
      hasCustomer: process.env.VUE_APP_IS_CUSTOMER,
      haveNoCompany: process.env.VUE_APP_REGISTER_HAVE_NO_COMPANY,
      hasBlueLogo: process.env.VUE_APP_REGISTER_HAS_BLUE_LOGO,
      has51WalletLogo: process.env.VUE_APP_REGISTER_HAS_51_WALLET_LOGO,
      has51WalletName: process.env.VUE_APP_REGISTER_HAS_51_WALLET_NAME,
      has51CardLogoAndName: process.env.VUE_APP_REGISTER_HAS_51_CARD_LOGO_AND_NAME,
      hasWeiXiangHuaLogoAndName: process.env.VUE_APP_REGISTER_HAS_WEI_XIANG_HUA_LOGO_AND_NAME,
      isTest: process.env.VUE_APP_IS_TEST,
      noBanner: process.env.VUE_APP_REGISTER_NO_BANNER,
      noCreditReport: process.env.VUE_APP_REGISTER_NO_CREDIT_REPORT,
      noHistory: process.env.VUE_APP_NO_HISTORY,
      companyName: process.env.VUE_APP_COMPANY_NAME || '',
      companyAddress: process.env.VUE_APP_COMPANY_ADDRESS || '',
      ICP: process.env.VUE_APP_ICP || '',
      loanPromptTextImageUrl: process.env.VUE_APP_REGISTER_LOAN_PROMPT_TEXT_IMAGE_URL
          || 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/bottom-tip.png',
      loanInfoContainerTitleImageUrl: process.env.VUE_APP_REGISTER_LOAN_INFO_CONTAINER_TITLE_IMAGE_URL
          || 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/loan-info-container-title.png',
      submitButtonText: process.env.VUE_APP_REGISTER_SUBMIT_BUTTON_TEXT || '去借钱',
      specialRegister: process.env.VUE_APP_SPECIAL_REGISTER,
      // 某条特殊渠道用户在第一次获取验证码的时候需要阻止并且弹出隐私协议弹窗
      stopGetVerificationCodeAndPopupSecretProtocol: process.env.VUE_APP_SAFE_VALIDATE,
      // 协议弹框点击遮罩层不关闭
      protocolPopupClickOverlayNotToHide: Boolean(process.env.VUE_APP_REGISTER_CLICK_PROTOCOL_POPUP_OVERLAY_NOT_TO_HIDE)
    }
  },
  methods: {
    // 限制借款输入最大值为9999999 最小值为0
    limitingLoanMoney() {
      this.loanMoney = this.loanMoney > 9999999 ? 9999999 : this.loanMoney
      this.loanMoney = this.loanMoney < 0 ? 0 : this.loanMoney
    },
    // 用户手指按至广告上注册事件
    touchstartRegisterBanner() {
      const thePage = document.documentElement || document.body
      thePage.classList.add('the-page-is-fixed')
      thePage.addEventListener('touchmove', this.bannerMove)
    },
    // 用户手指离开广告需要销毁事件
    touchendRegisterBanner() {
      const thePage = document.documentElement || document.body
      thePage.classList.remove('the-page-is-fixed')
      thePage.removeEventListener('touchmove', this.bannerMove)
    },
    // 广告移动方法
    bannerMove(event) {
      const bannerElement = this.$refs['register-banner-can-be-dragged']
      this.currentBannerX = document.documentElement.offsetWidth - event.touches[0].pageX - bannerElement.offsetWidth / 2
      this.currentBannerY = event.touches[0].pageY - bannerElement.offsetHeight / 2
    },
    // 获取验证码
    async getVerificationCode() {
      if (this.loginForm.phone.trim() === '') {
        this.$toast.fail('请填写手机号')
        return
      }
      if (!this.protocolIsSelected) {
        this.$toast.fail('请先阅读并勾选下方用户协议')
        return
      }

      if (this.stopGetVerificationCodeAndPopupSecretProtocol) {
        // 先弹出隐私协议弹窗
        this.showProtocolContentPopup(1)

        // 弹出以后将状态清除，这样再次调用函数就无需弹框
        this.stopGetVerificationCodeAndPopupSecretProtocol = false
        return
      }

      try {
        // 如果倒计时值为60则为可以获取验证码的状态，需要调用接口
        if (this.countDown === 60) {
          await get_code({
            phone: this.loginForm.phone,
            isH5: 1,
            channelCode: getSearch().channelSign
          })
          // 开启倒计时，先减一秒
          this.countDown--
          this.timer = setInterval(() => {
            this.countDown--
            if (this.countDown === 0) {
              clearInterval(this.timer)
              this.countDown = 60
            }
          }, 1000)
        }
        // 弹出验证码弹窗
        this.verificationCodeInputIsShow = true
      } catch (e) {}
    },
    async doLogin() {
      if (!this.protocolIsSelected) {
        this.$toast.fail('请先阅读并勾选下方用户协议')
        return
      }
      if (this.loginForm.phone.trim() === '') {
        this.$toast.fail('请填写手机号')
        return
      }
      // 这里获取的是组件得到的验证码
      this.loginForm.verificationCode = this.$refs['code-input-container'].aCheckCodeInput.join('')
      if (this.loginForm.verificationCode.trim().length !== 4) {
        this.$toast.fail('请正确填写验证码')
        return
      }
      // 判断手机系统类型
      const userAgent = navigator.userAgent
      const isAndroid = userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1
      if (this.stopLogin) {
        return
      }
      try {
        this.stopLogin = true
        const { data } = await do_login({
          phone: this.loginForm.phone,
          code: this.loginForm.verificationCode,
          phoneType: isAndroid ? 2 : 1,
          isH5: 1,
          channelSign: getSearch().channelSign
        })
        // 存token
        localStorage.setItem('token', data.only)
        // 跳转
        if (process.env.VUE_APP_REGISTER_TO_DOWNLOAD_NO_CACHE) {
          this.$toDownload(0, 1)
          return
        }

        if (process.env.VUE_APP_REGISTER_TO_DOWNLOAD) {
          this.$toDownload(0)
          return
        }
        if (process.env.VUE_APP_IS_TO_SUPER_LOAN) {
          localStorage.setItem('submitSuccess', '1')
          await this.$router.replace('/superLoan')
          return
        }
        localStorage.setItem('cacheTime', new Date().valueOf().toString())

        // 判断用户是否填写过资料
        if (data.completeH5Info === 1) {
          await this.$router.replace('/loanInfo')
        } else {
          // 将用户资料填入本地
          this.$setUserInfo(data.reH5UserInfoDto)

          // 去匹配产品
          await this.$router.replace('/matchBigLoanProduct')
        }
      } catch (e) {
        if (e.message === '验证码错误') {
          this.$refs['code-input-container'].aCheckCodeInput = ['', '', '', '']
          this.$refs['code-input-container'].$refs.firstInputRef.focus()
        }
      }
      this.stopLogin = false
    },
    // 点击广告的弹窗
    clickBannerShowToast() {
      this.$toast.fail('请点击‘去借钱’完善资料后再试')
    },
    // 获取协议内容
    async getProtocolContentHtmlList() {
      try {
        const { data } = await get_agreement_list()
        data.forEach((item) => {
          // 特殊渠道处理，协议里51微卡包替换为环境变量中配置的平台名称
          if (process.env.VUE_APP_PROTOCOL_PLATFORM_NAME) {
            item.content = item.content.replaceAll('51微卡包', process.env.VUE_APP_PROTOCOL_PLATFORM_NAME)
          }
          if (process.env.VUE_APP_PROTOCOL_COMPANY_NAME) {
            item.content = item.content.replaceAll('杭州多闪互联网广告有限公司', process.env.VUE_APP_PROTOCOL_COMPANY_NAME)
          }

          if (item.name === "用户注册协议") {
            this.protocolContentHtmlList[0] = item.content
          }
          if (item.name === "隐私协议") {
            this.protocolContentHtmlList[1] = item.content
          }
        })
      } catch (e) {}
    },
    /**
     * @description 展示协议内容弹框
     * @param index 0为注册协议 1为隐私协议
     * */
    showProtocolContentPopup(index) {
      this.protocolContentHtml = this.protocolContentHtmlList[index]

      if (this.protocolIsIframe) {
        this.protocolIframeUrl = this.protocolIframeUrlList[index]
      }

      this.protocolContentPopupIsShow = true
    },
    // 获取底部申请历史记录列表
    getSubmitHistoryList() {
      const indexList = []
      // 底部需要5个用户信息
      while (indexList.length !== 5) {
        // 在[0, 15)区间获取值
        const randomIndex = Math.floor(Math.random() * 15)
        // 如果索引不重复则添加至索引列表
        if (indexList.indexOf(randomIndex) === -1) {
          indexList.push(randomIndex)
        }
      }
      indexList.forEach((index) => {
        this.submitHistoryList.push(submitUserInfo[index])
      })
    },
    // 获取今日日期
    getDate() {
      const today = new Date()
      let year = today.getFullYear()
      let month = today.getMonth() + 1  // 月份从0开始，所以要加1
      let day = today.getDate()
      year = year > 10 ? year : '0' + year
      month = month > 10 ? month : '0' + month
      day = day > 10 ? day : '0' + day
      this.currentDay = year + '-' + month + '-' + day
    }
  },
  created() {
    if (process.env.VUE_APP_NO_CACHE_AND_NO_REGISTER_LOADING) {
      // 特殊渠道处理，进入页面即清除所有本地存储
      localStorage.clear()
    }

    this.getProtocolContentHtmlList()
    this.getSubmitHistoryList()
    this.getDate()
    // 这里是初始化用户资料数据，为后面的页面使用
    if (!this.$getUserInfo()) {
      this.$setUserInfo({})
    }
  }
}
</script>

<style lang="less" scoped>
@import url(~_assets/tool.less);

#register {
  min-height: 100vh;
  padding: 0.32rem;
  background: #F8F8F8;

  .register-banner-can-be-dragged {
    position: fixed;
    z-index: 3000;
    width: 71px;
  }

  .wallet-logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.267rem;

    img {
      width: 0.8rem;
    }

    span {
      margin-left: 0.133rem;
      color: #4775FF;
      font-size: 0.533rem;
    }
  }

  .loan-info-container {
    padding: 0.32rem;
    background: #FFFFFF;
    border-radius: 0.213rem;

    .title {
      margin: 0.213rem 0 0.32rem;
      font-size: 0.32rem;
      color: #999999;
    }

    .loan-money-container {
      .flex-y-center();
      justify-content: space-between;
      padding-bottom: 0.133rem;
      border-bottom: 1px solid #E0E0E0;

      input {
        width: 4.533rem;
        border: 0;
        outline: none;
        font-size: 1.067rem;
        font-weight: bold;
        color: #333333;
      }

      .loan-money-operate-container {
        .flex-y-center();
        flex: 1;
        font-size: 0.32rem;
        color: #366DF7;

        em {
          margin: 0 0.027rem 0 0.08rem;
          color: #999999;
        }

        img {
          width: 0.64rem;
        }
      }
    }

    .loan-way {
      margin-top: 0.267rem;
      background: #F8F8F8;
      border-radius: 0.213rem;
      font-size: 0.267rem;
      color: #999999;
      
      & > div {
        .flex-y-center();
        justify-content: space-between;
        padding: 0 0.32rem;
        height: 1.2rem;

        span {
          font-size: 0.373rem;
          font-weight: 600;
          color: #333333;
        }

        em {
          font-size: 0.32rem;
          color: #333333;
        }

        div {
          text-align: right;
        }
      }
    }
  }

  .login-form-container {
    margin: 0.213rem 0;
    padding: 0.32rem 0.32rem 0.427rem;
    background: #FFFFFF;
    border-radius: 0.213rem;
    
    input {
      width: 100%;
      height: 1.333rem;
      background: #F8F8F8;
      border: 0;
      border-radius: 0.16rem;
      font-size: 0.373rem;
      color: #9A9A9A;
      text-indent: 0.48rem;
    }

    .submit-button {
      .flex-center();
      height: 1.333rem;
      margin: 0.32rem 0 0.213rem;
      background: #325EF3;
      border-radius: 0.16rem;
      font-size: 0.427rem;
      font-weight: 500;
      color: #FFFFFF;
    }

    .protocol-container {
      .flex-center();
      font-size: 0.32rem;
      color: #5A5A5A;

      img {
        width: 0.32rem;
        margin-right: 0.107rem;
      }

      em {
        color: #325EF3;
      }
    }
  }

  .submit-history-list-container {
    overflow: hidden;

    & > img {
      width: 1.467rem;
      margin: 0.533rem 0 0.267rem;
    }

    .submit-history-list {
      overflow-x: auto;
      display: flex;
      width: 100%;

      .submit-history-list-item {
        flex-shrink: 0;
        position: relative;
        width: 5.333rem;
        margin-right: 0.213rem;
        padding: 0.187rem 0 0.24rem 0.213rem;
        background: #FFFFFF;
        border-radius: 0.213rem;
        font-size: 0.267rem;
        font-weight: 400;
        color: #999999;

        .submit-user-info {
          position: relative;
          height: 0.907rem;
          padding: 0.08rem 0 0 1.04rem;
          margin-bottom: 0.32rem;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 0.907rem;
            height: 0.907rem;
          }

          .user-name {
            font-size: 0.32rem;
            font-weight: 500;
            color: #333333;
          }
        }

        .submit-money {
          position: absolute;
          right: 0.32rem;
          top: 0.507rem;

          em {
            font-size: 0.427rem;
            font-weight: 500;
            color: #FF483B;
          }
        }
      }
    }
  }

  .loan-prompt-text {
    padding: 0.267rem 0.427rem 0.72rem;
  }

  footer {
    font-size: 12px;
    color: #a0a0a0;
    text-align: center;

    a {
      color: #a0a0a0;

      &:visited {
        color: #a0a0a0;
      }
    }

    .company-info {
      margin-bottom: 0.267rem;
    }
  }

  .verification-code-input-container {
    width: 8.72rem;
    padding: 0.32rem 0.427rem 0.56rem;

    h3 {
      font-size: 0.427rem;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }

    .count-down-text {
      padding-left: 0.32rem;
      font-size: 0.32rem;
      color: #999999;

      em {
        color: #325EF3;
      }
    }

    .submit-button {
      .flex-center();
      height: 1.333rem;
      margin-top: 0.533rem;
      background: #325EF3;
      border-radius: 0.667rem;
      font-size: 0.48rem;
      color: #FFFFFF;
    }
  }

  .protocol-content-popup-container {
    padding: 0.427rem;

    .protocol-content {
      overflow-y: auto;
      width: 100%;
      height: 8.7838rem;
    }

    .submit-button {
      .flex-center();
      height: 1.333rem;
      margin-top: 0.427rem;
      background: #325EF3;
      border-radius: 0.667rem;
      font-size: 0.48rem;
      color: #FFFFFF;
    }
  }
}
</style>