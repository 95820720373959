import request from '_utils/request'

const get_code = (params) => {
  return request({
    url: '/api/login/getCode',
    method: 'GET',
    params
  })
}

//登录
const do_login = (data) => {
  return request({
    url: "/api/login/signup-login",
    method: "POST",
    data,
  });
};

export {
  get_code,
  do_login
}