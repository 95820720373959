const submitUserInfo = [
  {
    name: '王先生',
    money: 200000,
    phoneNumber: '151****9996',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar1.png'
  },
  {
    name: '周先生',
    money: 120000,
    phoneNumber: '188****3121',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar2.png'
  },
  {
    name: '张先生',
    money: 50000,
    phoneNumber: '187****6820',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar3.png'
  },
  {
    name: '刘先生',
    money: 20000,
    phoneNumber: '138****7459',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar4.png'
  },
  {
    name: '倪女士',
    money: 3000,
    phoneNumber: '136****3423',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar5.png'
  },
  {
    name: '张女士',
    money: 120000,
    phoneNumber: '135****3056',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar6.png'
  },
  {
    name: '侯先生',
    money: 50000,
    phoneNumber: '136****2103',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar7.png'
  },
  {
    name: '赵先生',
    money: 12500,
    phoneNumber: '199****0207',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar8.png'
  },
  {
    name: '向先生',
    money: 6000,
    phoneNumber: '157****2669',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar9.png'
  },
  {
    name: '杨先生',
    money: 300000,
    phoneNumber: '159****7262',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar10.png'
  },
  {
    name: '刘女士',
    money: 5000,
    phoneNumber: '177****7811',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar11.png'
  },
  {
    name: '李女士',
    money: 30000,
    phoneNumber: '136****5219',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar12.png'
  },
  {
    name: '潘先生',
    money: 50000,
    phoneNumber: '184****2106',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar13.png'
  },
  {
    name: '蒙先生',
    money: 160000,
    phoneNumber: '186****1160',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar14.png'
  },
  {
    name: '赵女士',
    money: 40000,
    phoneNumber: '155****6268',
    avatarUrl: 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/register/avatar15.png'
  }
]

export default submitUserInfo
